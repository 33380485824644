import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';

import PreviewCompatibleImage from '../PreviewCompatibleImage';
import FormattedDate from '../FormattedDate';

import styles from './styles.module.scss';

const getPostDate = ({ frontmatter: { eventDate, date } }) => {
  return new Date(eventDate || date);
};

const BlogIndex = ({
  data: {
    allMarkdownRemark: { edges: posts },
  },
}) => {
  const now = new Date();
  const filteredPosts = posts.filter(
    ({
      node: {
        frontmatter: { endDate },
      },
    }) => !endDate || new Date(endDate) > now
  );

  filteredPosts.sort((a, b) => getPostDate(b.node) - getPostDate(a.node));

  return (
    <div className={styles.posts}>
      {filteredPosts &&
        filteredPosts.map(({ node: post }) => (
          <article
            key={post.id}
            className={`${styles.post} ${
              post.frontmatter.featuredpost ? 'top-actu' : ''
            }`}
          >
            <header>
              <p className={styles.post__date}>
                <FormattedDate date={getPostDate(post)} format="d MMMM yyyy" />
              </p>
              {post.frontmatter.featuredimage ? (
                <div className={styles.post__cover}>
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: post.frontmatter.featuredimage,
                      alt: `featured image thumbnail for post ${post.title}`,
                    }}
                  />
                </div>
              ) : null}
              <h2 className={styles.post__title}>
                <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
              </h2>
            </header>
            <section className={styles.post__excerpt}>
              <Link to={post.fields.slug} className={styles.post__link}>
                <span className={styles.post__linklabel}>Lire l'article</span>
              </Link>
            </section>
          </article>
        ))}
    </div>
  );
};

BlogIndex.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogIndexQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date
                eventDate
                endDate
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogIndex data={data} count={count} />}
  />
);
