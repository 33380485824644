import React from 'react'

import Layout from '../../components/Layout'
import BlogIndex from '../../components/BlogIndex'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <BlogIndex />
      </Layout>
    )
  }
}
